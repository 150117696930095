import { Button, Headline, Subtext } from 'components/Styled'

type EmbedConfig = {
  id?: string
  background_color: string
  headline: string
  headline_text_color: string
  subtext: string
  subtext_text_color: string
  button_background_color: string
  button_text_color: string
  phone_number: string
  name: string
  code: string
}

type MobileProps = {
  config: EmbedConfig
  onClose?: () => void
  onSubmit: () => void
}

export const Mobile = ({ config, onSubmit }: MobileProps): JSX.Element | null => {
  const body = encodeURIComponent(
    `Send this message for an invite to text with ${config.name} via Community (Reference code: ${config.code})`,
  )
  return (
    <>
      <Headline>{config.headline}</Headline>
      <Subtext>{config.subtext}</Subtext>
      <Button as="a" href={`sms://+${config.phone_number}?&body=${body}`} onClick={onSubmit} target="_top">
        Send Text
      </Button>
    </>
  )
}
