import { FONT_WEIGHT } from '@community_dev/pixels'
import styled from 'styled-components'

export const Headline = styled.h1`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.H2};
  color: ${({ theme }) => theme?.headline_text_color};
  margin-top: 0;
  text-align: center;
`

export const Subtext = styled.h2`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY2};
  font-weight: ${FONT_WEIGHT[3]};
  color: ${({ theme }) => theme?.subtext_text_color};
  text-align: center;
  margin-bottom: 24px;
`
export const Button = styled.button`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.BODY1};
  font-weight: 600;
  background: ${({ disabled, theme }) => (disabled ? theme?.COLORS?.BUTTON_DISABLED : theme?.button_background_color)};
  color: ${({ disabled, theme }) => (disabled ? theme?.COLORS?.BUTTON_DISABLED_TEXT : theme?.button_text_color)};
  border: 1px solid ${({ theme }) => theme?.button_border_color ?? 'transparent'};
  border-radius: 333px;
  padding: 8px 16px;
  display: block;
  width: 100%;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
`
