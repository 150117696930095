import { SPACING, ThemeProvider } from '@community_dev/pixels'
import LIGHT from '@community_dev/pixels/dist/js/themes/presets/light'
import { useEffect, useState } from 'react'
import Measure from 'react-measure'
import styled from 'styled-components'

import { EmbedConfig } from './api/config'
import { Desktop } from './screens/Desktop'
import { Mobile } from './screens/Mobile'

type Message = {
  action: string
  payload?: any
}

const Backdrop = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
`
const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`
const Modal = styled.div`
  background: ${({ theme }) => theme?.background_color};
  border-radius: 16px;
  padding: 72px 32px 32px;
  margin: 16px;
  width: 100%;
  max-width: 480px;
  position: relative;
  pointer-events: auto;
`
const ModalClose = styled.button`
  position: absolute;
  top: 30px;
  right: 38px;
  background: transparent;
  border: none;
`

const EmbedBackground = styled.div`
  background: ${({ theme }) => theme?.background_color};
  margin: 0 auto;
  padding: ${SPACING[8]};
  max-width: 480px;
`

const handleResize = (bounds) =>
  window.parent.postMessage(
    JSON.stringify({
      action: 'resize',
      height: Math.ceil(bounds.height) + 'px',
      width: Math.ceil(bounds.width) + 'px',
    }),
    '*',
  )

export const App = (): JSX.Element | null => {
  const [config, setConfig] = useState<EmbedConfig | null>(null)
  const [isMobile] = useState(() =>
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
  )
  const [messagePort, setMessagePort] = useState<MessagePort | null>(null)

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (window.parent !== event.source && event.origin !== window.origin) return
      let { data } = event
      try {
        data = window.JSON.parse(data) as Message
      } catch (e) {
        // do nothing
      }
      switch (data.action) {
        case 'initChannel': {
          const {
            ports: [port1],
          } = event

          if (port1 !== undefined) {
            setMessagePort(port1)
          }
          break
        }
        case 'setConfig':
          setConfig(data.payload)
          break
        default:
          break
      }
    }
    window.addEventListener('message', handleMessage, false)

    window.parent.postMessage(window.JSON.stringify({ action: 'ready' }), '*')

    return () => {
      window.removeEventListener('message', handleMessage, false)
    }
  }, [])

  if (!config) return null

  const handleSubmit = () => {
    window.parent.postMessage(window.JSON.stringify({ action: 'submit' }), '*')
    messagePort?.postMessage({ action: 'submit' })
  }

  const close = (): void => {
    window.parent.postMessage(window.JSON.stringify({ action: 'close' }), '*')
    messagePort?.postMessage({ action: 'close' })
  }

  let Component = isMobile ? Mobile : Desktop

  // allow slate preview to force mobile or desktop view
  if (config.force_view !== undefined) {
    Component = config.force_view === 'mobile' ? Mobile : Desktop
  }

  return (
    <ThemeProvider
      theme={{
        ...LIGHT,
        ...config,
      }}
    >
      <Measure bounds onResize={(contentRect) => handleResize(contentRect.bounds)}>
        {({ measureRef }) => (
          <div className="App" data-testid="app" ref={measureRef}>
            {config.display_type === 'inline' ? (
              <EmbedBackground>
                <Component config={config} onSubmit={handleSubmit} />
              </EmbedBackground>
            ) : (
              <>
                <Backdrop onClick={close} />
                <ModalWrapper>
                  <Modal>
                    <ModalClose aria-label="Close" onClick={close} type="button">
                      <svg fill="none" height="13" viewBox="0 0 12 13" width="12" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M8.27455 6.81836L11.686 3.40688C12.1047 2.98825 12.1047 2.3095 11.686 1.89052L10.9278 1.13234C10.5092 0.7137 9.83045 0.7137 9.41148 1.13234L6 4.54381L2.58852 1.13234C2.16989 0.7137 1.49114 0.7137 1.07216 1.13234L0.313977 1.89052C-0.104659 2.30915 -0.104659 2.9879 0.313977 3.40688L3.72545 6.81836L0.313977 10.2298C-0.104659 10.6485 -0.104659 11.3272 0.313977 11.7462L1.07216 12.5044C1.4908 12.923 2.16989 12.923 2.58852 12.5044L6 9.09291L9.41148 12.5044C9.83011 12.923 10.5092 12.923 10.9278 12.5044L11.686 11.7462C12.1047 11.3276 12.1047 10.6488 11.686 10.2298L8.27455 6.81836Z"
                          fill="black"
                          fillOpacity="0.48"
                        />
                      </svg>
                    </ModalClose>
                    <Component config={config} onClose={close} onSubmit={handleSubmit} />
                  </Modal>
                </ModalWrapper>
              </>
            )}
          </div>
        )}
      </Measure>
    </ThemeProvider>
  )
}
