import 'react-app-polyfill/stable'
import path from 'path'

import { GlobalStyle } from '@community_dev/pixels'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { createGlobalStyle } from 'styled-components'

import { App } from './App'

import Sentry from 'integrations/Sentry'

if (import.meta.env.DEV) {
  import('./mocks/browser')
    .then(({ worker }) => {
      worker.start({
        serviceWorker: {
          url: path.join(import.meta.env.VITE_PUBLIC_URL, '/mockServiceWorker.js'),
        },
        onUnhandledRequest: 'bypass',
      })
    })
    .catch(Sentry.captureException)
}

const StyledGlobal = createGlobalStyle`
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
  }

  body {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.096px;
  }
`

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <React.StrictMode>
    <>
      <GlobalStyle />
      <StyledGlobal />
      <App />
    </>
  </React.StrictMode>,
)

declare global {
  interface Window {
    Cypress: unknown
    Community: {
      createSubscription(phone: string): void
      subscribed: boolean
    }
  }
}
