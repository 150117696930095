import { ParseError, PhoneNumber, parsePhoneNumberWithError } from 'libphonenumber-js'

import { API_URL } from 'constants/urls'

type CreateSubscriptionParams = {
  id: string
  phone: string
}

export const ERRORS = {
  INVALID_NUMBER: 'INVALID_NUMBER',
  ALREADY_SUBSCRIBED: 'ALREADY_SUBSCRIBED',
  UNEXPECTED_ERROR: 'UNEXPECTED_ERROR',
  NOT_A_NUMBER: 'NOT_A_NUMBER',
  TOO_SHORT: 'TOO_SHORT',
  TOO_LONG: 'TOO_LONG',
  INVALID_COUNTRY: 'INVALID_COUNTRY',
}

export async function createSubscription({ id, phone }: CreateSubscriptionParams): Promise<PhoneNumber> {
  const genericError = ERRORS.INVALID_NUMBER

  let parsedPhoneNumber: PhoneNumber
  try {
    parsedPhoneNumber = parsePhoneNumberWithError(phone, 'US')
  } catch (err) {
    if (err instanceof ParseError) {
      throw new Error(err.message)
    }

    throw new Error(genericError)
  }

  if (!parsedPhoneNumber.isValid()) {
    throw new Error(genericError)
  }

  let res: Response
  try {
    res = await fetch(`${API_URL}/member-growth-units/v1/embeds/${id}/activations`, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        fan_phone_number: parsedPhoneNumber.number.replace(/\D/g, ''),
      }),
    })
  } catch (err) {
    throw new Error(ERRORS.UNEXPECTED_ERROR)
  }

  if (!res.ok) {
    try {
      const data = await res.json()
      const alreadySubscribed = data.errors?.some((e) => e?.message?.includes('already activated'))
      if (!alreadySubscribed) {
        throw new Error(ERRORS.UNEXPECTED_ERROR)
      }
    } catch (e) {
      throw new Error(ERRORS.UNEXPECTED_ERROR)
    }
  }

  return parsedPhoneNumber
}
