import { TextInput } from '@community_dev/pixels'
import LIGHT from '@community_dev/pixels/dist/js/themes/presets/light'
import parsePhoneNumber, { AsYouType } from 'libphonenumber-js'
import { useState } from 'react'
import styled, { DefaultTheme, useTheme } from 'styled-components'

import { createSubscription } from 'api/subscription'
import { Button, Headline, Subtext } from 'components/Styled'

type EmbedConfig = {
  id?: string
  background_color: string
  headline: string
  headline_text_color: string
  subtext: string
  subtext_text_color: string
  button_background_color: string
  button_text_color: string
  phone_number: string
  name: string
}

type DesktopProps = {
  config: EmbedConfig
  onClose?: () => void
  onSubmit: () => void
}

const StyledLegal = styled.p`
  ${({ theme }) => theme?.TYPOGRAPHY?.VARIANT?.CAPTION2};
  font-weight: 500;
  color: ${({ theme }) => theme?.subtext_text_color};
  opacity: 0.7;

  a {
    color: inherit;
  }
`

const StyledInputWrapper = styled.div`
  label,
  input {
    color: ${({ theme }) => theme?.subtext_text_color} !important;
  }
`

export const Desktop = ({ config, onClose, onSubmit }: DesktopProps): JSX.Element | null => {
  const { COLORS } = useTheme() || {}
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isTouched, setIsTouched] = useState(false)
  const [isValid, setIsValid] = useState(false)

  if (isSubmitted) {
    return (
      <>
        <Headline>Thank you</Headline>
        <Subtext>Check your texts, if you’re not already subscribed.</Subtext>
        {Boolean(onClose) && (
          <Button
            onClick={onClose}
            theme={
              {
                ...LIGHT,
                ...config,
                button_background_color: COLORS?.APP_BACKGROUND_LEVEL_3,
                button_text_color: COLORS?.TEXT,
                button_border_color: COLORS?.BORDERS,
              } as DefaultTheme
            }
          >
            Close
          </Button>
        )}
      </>
    )
  }

  return (
    <form
      onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault()
        if (!isTouched) {
          setIsTouched(true)
        }
        if (!isValid) {
          return
        }
        if (config.id) {
          createSubscription({ phone: phoneNumber, id: config.id })
            .catch((e) => console.error(e))
            .finally(() => {
              onSubmit()
              setIsSubmitted(true)
            })
        }
      }}
    >
      <Headline>{config.headline}</Headline>
      <Subtext>{config.subtext}</Subtext>
      <StyledInputWrapper>
        <TextInput
          error={!isValid && isTouched ? 'Must be a valid (+1 US or Canada) phone number' : undefined}
          label="Phone Number"
          name="phoneNumber"
          onBlur={(): void => {
            if (!isTouched) {
              setIsTouched(true)
            }
          }}
          onChange={(e: React.FormEvent<HTMLInputElement>): void => {
            const strippedNumber = e.currentTarget.value.replace(/(^\+?1?\s?)|[^0-9]+/g, '')
            const formatted = new AsYouType('US').input(strippedNumber).replace(/\)$/, '')
            const parsedPhoneNumberValid =
              strippedNumber.length === 10 && parsePhoneNumber(e.currentTarget.value, 'US')?.isValid()
            if (parsedPhoneNumberValid !== isValid) {
              setIsValid(!isValid)
            }
            setPhoneNumber(formatted)
          }}
          showLabelInError={false}
          type="text"
          value={`+1 ${phoneNumber}`}
        />
      </StyledInputWrapper>
      <StyledLegal>
        By providing your number and clicking "Send Text," you consent to receive recurring marketing and other
        autotexts from {config.name} via Community and agree to Community's{' '}
        <a href="https://www.community.com/terms" rel="noopener noreferrer" target="_blank">
          Terms of Use
        </a>{' '}
        and{' '}
        <a href="https://www.community.com/privacy-policy" rel="noopener noreferrer" target="_blank">
          Privacy Policy
        </a>
        . Consent not req'd or condition of purchase. Msg&amp;data rates may apply.
      </StyledLegal>
      <Button disabled={!isValid && isTouched} type="submit">
        Send Text
      </Button>
    </form>
  )
}
